import { wrapLoadWithSentry } from '@sentry/sveltekit'
import type { SeoTags } from '$lib/models'
import * as m from '$lib/paraglide/messages'

export const prerender = true

export const load = wrapLoadWithSentry(() => {
	const seoTags = Object.freeze<SeoTags>({
		title: m.home_seo_title(),
		description: m.home_seo_description(),
		canonical: 'https://chainspect.app/',
		image: `https://chainspect.app/og?title=${encodeURIComponent(m.home_seo_title())}`,
	})

	return {
		seoTags,
	}
})
